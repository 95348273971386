import { left, right, Either } from 'fp-ts/es6/Either';
import { IS_PROD } from '../config';
import { objIsEmpty } from 'helpers/objects';
import { isLocal, isNotServerSideRendering } from 'helpers/env';

// Makes an http request and transforms the reponse into JSON
export const fetchJson = async (path: string, options: object = {}) =>
  fetch(path, options)
    .then(res => res.json())
    .catch(e => console.error(e));

export const HTTP_GET = 'GET';
export const HTTP_PATCH = 'PATCH';
export const HTTP_POST = 'POST';
export const HTTP_PUT = 'PUT';
export const HTTP_DELETE = 'DELETE';
export const HTTP_OPTIONS = 'OPTIONS';

export const LOGOUT_ROUTE = '/customer/account/logout';

const FASTLY_GEOLOCATION_COOKIE_NAME = 'fastly-country-code';
const USA_COUNTRY_CODE = 'US';
const EU_COUNTRY_CODES = 'BE,BG,CZ,DK,DE,EE,IE,EL,ES,FR,HR,IT,CY,LV,LT,LU,HU,MT,NL,AT,PL,PT,RO,SI,SK,FI,SE,UK';

// BuildParamString :: {string: string} -> string
export const buildParamString = (params: { [key: string]: string }): string =>
  Object.entries(params).reduce(
    (accum, [key, val], index) => {
      return `${accum}${index !== 0 ? '&' : ''}${key}=${val}`;
    },
    objIsEmpty(params) ? '' : '?'
  );

// HostWithPathAndParams :: string -> [string] -> {string: string} -> string
export const hostWithPathAndParams = (host: string) => (...path: string[]) => (
  params: { [key: string]: string } = {}
): string => `${host}/${path.join('/')}${buildParamString(params)}`;

export interface ApiRequestFailure {
  reason: string;
  message?: string;
  statusCode?: number;
  body?: any;
}

export const isApiRequestFailure = (obj: any): obj is ApiRequestFailure => {
  return (
    typeof obj === 'object' &&
    (obj.hasOwnProperty('reason') ||
      obj.hasOwnProperty('message') ||
      (obj.hasOwnProperty('statusCode') && obj.statusCode < 500))
  );
};

// ApiRequest T :: string -> string -> [string] -> ?{} -> Promise<T>
export const apiRequest = (url: string, includeCredentials = true) => (...path: string[]) => (method: string) => (
  headers: { [key: string]: string } = {}
) => <T>(requestData: { [key: string]: any } | undefined = undefined): Promise<Either<ApiRequestFailure, T>> => {
  return fetch(hostWithPathAndParams(url)(...path)(requestData && method === HTTP_GET ? requestData : {}), {
    method,
    redirect: 'follow',
    credentials: includeCredentials ? 'include' : 'omit',
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    ...(requestData && method !== HTTP_GET ? { body: JSON.stringify(requestData) } : {})
  })
    .then(response => {
      if (response.status > 299) {
        return response.json().then(x => {
          throw {
            statusCode: response.status,
            body: x,
            reason: 'non-200 response'
          };
        });
      }

      if (response.status === 204) {
        return right<ApiRequestFailure, T>({} as T);
      }

      return response
        .json()
        .then(x => right<ApiRequestFailure, T>(x))
        .catch(e => {
          throw {
            statusCode: response.status,
            reason: e.message
          };
        });
    })
    .catch(e => {
      if (!IS_PROD) {
        console.error(e);
      }
      if (!isApiRequestFailure(e)) {
        return left<ApiRequestFailure, T>({
          reason: e.message || 'Request failed for unknown reason'
        });
      }
      return left<ApiRequestFailure, T>(e);
    });
};

export const getCookie = (cookie: string) => {
  if (typeof document !== 'undefined') {
    const cookieString = document.cookie.split(';').filter(item => item.includes(cookie));
    const regex = /=(.+)/g;

    if (cookieString.length) {
      return decodeURIComponent(regex.exec(cookieString[0])[1]);
    }
  }
  return false;
};

/**
 * Sets a cookie with a predetemined expiry or optionally as a session only cookie
 *
 * @param {string} name Cookie name
 * @param {string | boolean} value Cookie value
 * @param {number | null} maxAge Optional, if no value provided will default to 2592000
 * if null provided maxAge will not be set resulting in a session only cookie
 * @return {void}
 *
 */
export const setCookie = (name: string, value: string | boolean, maxAge: number | null = 2592000): void => {
  if (!isNotServerSideRendering()) {
    return;
  }

  const newCookie = [
    `${name}=${encodeURIComponent(value)}`,
    'path=/',
    `domain=${isLocal() ? 'localhost' : process.env.GATSBY_COOKIE_DOMAIN}`,
    maxAge ? `max-age=${maxAge}` : ''
  ].join(';');

  document.cookie = newCookie;
};

// Resets a cookie by expiring it
export const resetCookie = (domain: string, name: string, path = '/'): void => {
  document.cookie = [`${name}=`, `path=${path}`, `domain=${domain}`, 'expires=Thu, 01-Jan-1970 00:00:01 GMT'].join(';');
};

export const deleteCookie = (name: string) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export function checkIsDevelopmentBuild() {
  const env = process.env.GATSBY_SITE_ENV.toLowerCase();
  const isDevelopmentBuild = env === 'local';
  return isDevelopmentBuild;
}

// An expired cookie is automatically deleted
export const removeUserCookies = (cookiesToPersist: Array<string> = []): void => {
  // Some cookies have .artifactuprising.com or striveendureance.com so we'll expire both
  const baseDomains = [process.env.GATSBY_COOKIE_DOMAIN, `www${process.env.GATSBY_COOKIE_DOMAIN}`];
  const cookies = document.cookie.split('; ');
  for (let c = 0; c < cookies.length; c++) {
    for (let d = 0; d < baseDomains.length; d++) {
      const cookieName = encodeURIComponent(cookies[c].split(';')[0].split('=')[0]);
      // If we want to persist the cookie, don't do anything to it.
      if (cookiesToPersist.includes(cookieName)) {
        continue;
      }
      resetCookie(baseDomains[d], cookieName);
    }
  }
};

// Checks the fastly-country-code cookie to determine a users country
export const userLocatedInAmerica = () => {
  return isLocal() || getCookie(FASTLY_GEOLOCATION_COOKIE_NAME) === USA_COUNTRY_CODE;
};

export const userLocatedInEU = (): string | boolean => {
  const geoCode = getCookie(FASTLY_GEOLOCATION_COOKIE_NAME);

  return geoCode && EU_COUNTRY_CODES.includes(geoCode);
};

// Check if a link matches the origin of the host we're currently at
export const isLinkSameOriginAsHost = (link: string): boolean => {
  return link.includes(window.location.hostname);
};

export const setUrlParam = (uri: string, key: string, val: any) => {
  return uri
    .replace(RegExp(`([?&]${key}(?=[=&#]|$)[^#&]*|(?=#|$))`), `&${key}=${encodeURIComponent(val)}`)
    .replace(/^([^?&]+)&/, '$1?');
};

export const setUrlParams = (uri: string, params: { [key: string]: any } = {}) => {
  return Object.keys(params).reduce((uriWithParams, key) => {
    return setUrlParam(uriWithParams, key, params[key]);
  }, uri);
};

export const auRequest = (url: string, includeCredentials = true) => <T>(
  path: string,
  method: string,
  headers: { [key: string]: string } = {},
  requestData: { [key: string]: any } | undefined = undefined
): Promise<Either<ApiRequestFailure, T>> =>
  fetch(hostWithPathAndParams(url)(path)(requestData && method === HTTP_GET ? requestData : {}), {
    method,
    redirect: 'follow',
    credentials: includeCredentials ? 'include' : 'omit',
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    ...(requestData && method !== HTTP_GET ? { body: JSON.stringify(requestData) } : {})
  })
    .then(response => {
      if (response.status > 299) {
        return response.json().then(x => {
          throw {
            statusCode: response.status,
            body: x,
            reason: 'non-200 response'
          };
        });
      }

      if (response.status === 204) {
        return right<ApiRequestFailure, T>({} as T);
      }

      return response
        .json()
        .then(x => right<ApiRequestFailure, T>(x))
        .catch(e => {
          throw {
            statusCode: response.status,
            reason: e.message
          };
        });
    })
    .catch(e => {
      if (!IS_PROD) {
        console.error(e);
      }
      if (!isApiRequestFailure(e)) {
        return left<ApiRequestFailure, T>({
          reason: e.message || 'Request failed for unknown reason'
        });
      }
      return left<ApiRequestFailure, T>(e);
    });
