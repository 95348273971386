import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';

import { isValidEmail } from 'helpers/strings';

import { Column, Grid, StyledP } from 'component-library';
import { Show } from 'components/Functional';
import { openDialog as openConsentManager } from 'components/SegmentConsentManager/container';

import { newsletterSignUp } from 'services/magenum/customer';

import {
  CopyrightGrid,
  DesktopFooterNav,
  FooterEmailForm,
  FooterNavWrapper,
  FooterStyle,
  MobileFooterNav
} from './styled';
import { customerIdSelector } from 'store/customer/selectors';
import { useSelector } from 'react-redux';
import itly from 'itly';
import './footer.css';

const desktopLinks = ({ items }) =>
  items?.map((item) => {
    return (
      <div className="footer-nav" key={`desktop-footer-link-group-${item.name}`}>
        <p className="nav-header">{item.name}</p>
        {item.links.map((link) => {
          return <a key={link.href} className="footer-nav-link" href={link.href} target="_blank" rel="noreferrer">{link.label}</a>;
        })}
      </div>
    );
  });

const Footer = () => {
  const { allFooterMenuGroup } = useStaticQuery(graphql`
    query {
      allFooterMenuGroup {
        edges {
          node {
            groupName
            items {
              name
              links {
                newTab
                label
                href
              }
            }
          }
        }
      }
    }
  `);

  const footerMenu = allFooterMenuGroup?.edges.reduce((acc, curr) => {
    return [...acc, curr.node];
  }, []);

  const desktopFooter = footerMenu.find(set => set.groupName === 'desktop');

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const customerId = useSelector(customerIdSelector);

  return (
    <footer>
      <FooterStyle>
        <Column size={12} medium={6}>
          <div className="email-input-wrapper">
            <div className="email-input-head">Create your best photo album, ever.</div>
            <div className="email-input-subhead">
              Get insider photo tips, exclusive offers, new product <br></br> announcements, and more in your inbox.
            </div>
            <Show when={success}>
              <h4 className="newsletter-success-msg">Thank you for signing up!</h4>
            </Show>
            <Show when={!success}>
              <FooterEmailForm
                placeholder="Your Email Address"
                onSubmit={async () => {
                  if (!isValidEmail(email)) {
                    setError('Please enter a valid email address.');
                  } else {
                    setIsLoading(true);
                    await newsletterSignUp(email).then(response => {
                      response
                        .map(x => {
                          setIsLoading(false);
                          setSuccess(true);

                          itly.emailSubscribed({
                            customer_id: customerId.toString(),
                            email: email,
                            subscription: 'subscribed',
                            location: 'footer',
                            last_updated: new Date().toISOString()
                          });

                          setTimeout(() => {
                            // After 5 seconds, clear success message re-render form
                            setEmail('');
                            setSuccess(false);
                          }, 5000);
                        })
                        .getOrElseL(x => {
                          setSuccess(false);
                        });
                    });
                  }
                }}
                value={email}
                error={error}
                onChange={value => {
                  setEmail(value);
                  setError('');
                }}
                submitButtonLabel="Sign Up"
                isLoading={isLoading}
              />
            </Show>
          </div>
        </Column>
        <Column size={12} medium={6}>
          <DesktopFooterNav>
            <FooterNavWrapper>{desktopLinks(desktopFooter)}</FooterNavWrapper>
          </DesktopFooterNav>
          <MobileFooterNav>
            <div className="footer-nav-wrapper">{desktopLinks(desktopFooter)}</div>
          </MobileFooterNav>
        </Column>
      </FooterStyle>
      <div className="copyright-wrapper">
        <CopyrightGrid>
          <div className="copyright-grid-column" size={6}>
            <ul className="copyright">
              <li className="copyright-item">&copy;{new Date().getFullYear()} Artifact Uprising®.</li>
              <li className="copyright-item">All Rights Reserved</li>
            </ul>
          </div>
          <div className="copyright-grid-column" size={6}>
            <ul className="copyright-list">
              <li className="copyright-list-item">
                <a href="https://striveendureance.com/terms-conditions">Terms of Service</a>
              </li>
              <li className="copyright-list-item">
                <a href="https://striveendureance.com/sitemap">Site Map</a>
              </li>
              <li className="copyright-list-item">
                <a href="https://striveendureance.com/privacy">Privacy</a>
              </li>
              <li className="copyright-list-item">
                <button
                  id="cookie-consent-button"
                  className="cookie-consent-show-settings"
                  onClick={event => {
                    event.nativeEvent.stopImmediatePropagation();
                    openConsentManager();
                  }}
                >
                  Cookie Settings
                </button>
              </li>
            </ul>
          </div>
        </CopyrightGrid>
      </div>
    </footer>
  );
};

export default Footer;
